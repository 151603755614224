import ACTION, {
  FORM_NEXT_STATE,
  UPLOAD_ROADTAX_FILE_STATE,
  UPLOAD_DRIVINGLICENSE_FILE_STATE,
  UPLOAD_DRIVINGLICENSEBACK_FILE_STATE,
  UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE,
  GET_VEHICLE_STATE,
} from './creator'

const init_save = {
  status: FORM_NEXT_STATE.PENDING,
  message: '',
}
const init_update = {
  noPlat: '',
  newVehiclePass: false,
  vehicleType: [],
  licenseClass: [],
  workingCompany: '',
  workingCompanyData: [],
  vehicleTypeRenew: '',
  roadTaxExpired: '',
  licenseExpired: '',
  paymentMethod: [
    {
      id: '2',
      value: 'Cash',
      label: 'Cash / Tunai',
      checked: true,
      disabled: false,
    },
    {
      id: '3',
      value: 'Online',
      label: 'Online / Atas Talian',
      checked: false,
      disabled: false,
    },
    {
      id: '1',
      value: 'Company',
      label: 'Company / Syarikat',
      checked: false,
      disabled: true,
    },
  ],
  roadTax: '',
  drivingLicense: '',
  drivingLicenseBack: '',
  additionalDrivingLicense: '',
  formIdResponse: '',
  nextPage: false,
}
const init_roadtax = {
  status: UPLOAD_ROADTAX_FILE_STATE.PENDING,
  message: '',
}
const init_drivinglicense = {
  status: UPLOAD_DRIVINGLICENSE_FILE_STATE.PENDING,
  message: '',
}
const init_drivinglicenseback = {
  status: UPLOAD_DRIVINGLICENSEBACK_FILE_STATE.PENDING,
  message: '',
}
const init_additionaldrivinglicense = {
  status: UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE.PENDING,
  message: '',
}
const init_vehicle = {
  status: GET_VEHICLE_STATE.PENDING,
  message: '',
}

const nextRenewRegistrationVehiclePassData = (state = init_save, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_NEXT.SUCCESS:
    case ACTION.FORM_NEXT.FAILURE:
    case ACTION.FORM_GETID.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
const updateRenewFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
    case ACTION.CLEAR_ALL_FORM:
      return init_update
    default:
      return state
  }
}

const uploadRenewRoadTaxFile = (state = init_roadtax, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_ROADTAX_FILE.PENDING:
    case ACTION.UPLOAD_ROADTAX_FILE.SUCCESS:
    case ACTION.UPLOAD_ROADTAX_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_roadtax
    default:
      return state
  }
}

const uploadRenewDrivingLicenseFile = (state = init_drivinglicense, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.PENDING:
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.SUCCESS:
    case ACTION.UPLOAD_DRIVINGLICENSE_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_drivinglicense
    default:
      return state
  }
}

const uploadRenewDrivingLicenseBackFile = (state = init_drivinglicenseback, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.PENDING:
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.SUCCESS:
    case ACTION.UPLOAD_DRIVINGLICENSEBACK_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_drivinglicenseback
    default:
      return state
  }
}

const uploadRenewAdditionalDrivingLicenseFile = (
  state = init_additionaldrivinglicense,
  { type, payload },
) => {
  switch (type) {
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.PENDING:
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.SUCCESS:
    case ACTION.UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_additionaldrivinglicense
    default:
      return state
  }
}
const getVehicle = (state = init_vehicle, { type, payload }) => {
  switch (type) {
    case ACTION.GET_VEHICLE.PENDING:
    case ACTION.GET_VEHICLE.SUCCESS:
    case ACTION.GET_VEHICLE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export {
  nextRenewRegistrationVehiclePassData,
  updateRenewFormData,
  uploadRenewRoadTaxFile,
  uploadRenewDrivingLicenseFile,
  uploadRenewDrivingLicenseBackFile,
  uploadRenewAdditionalDrivingLicenseFile,
  getVehicle,
}
