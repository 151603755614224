const BODYPASS_ACTIONS = {
  USERINFO: {
    PENDING: 'USERINFO_PENDING',
    SUCCESS: 'USERINFO_SUCCESS',
    FAILURE: 'USERINFO_FAILURE',
    UPDATE: 'USERINFO_UPDATE',
  },
  WORKAREA: {
    PENDING: 'WORKAREA_PENDING',
    SUCCESS: 'WORKAREA_SUCCESS',
    FAILURE: 'WORKAREA_FAILURE',
    UPDATE: 'WORKAREA_UPDATE',
  },
  DEPARTMENT: {
    PENDING: 'DEPARTMENT_PENDING',
    SUCCESS: 'DEPARTMENT_SUCCESS',
    FAILURE: 'DEPARTMENT_FAILURE',
    UPDATE: 'DEPARTMENT_UPDATE',
  },
  LOCATION: {
    PENDING: 'LOCATION_PENDING',
    SUCCESS: 'LOCATION_SUCCESS',
    FAILURE: 'LOCATION_FAILURE',
    UPDATE: 'LOCATION_UPDATE',
  },
  EMPLOYER_COMPANY: {
    PENDING: 'EMPLOYER_COMPANY_PENDING',
    SUCCESS: 'EMPLOYER_COMPANY_SUCCESS',
    FAILURE: 'EMPLOYER_COMPANY_FAILURE',
    UPDATE: 'EMPLOYER_COMPANY_UPDATE',
  },
  PHONE_CODE: {
    PENDING: 'PHONE_CODE_PENDING',
    SUCCESS: 'PHONE_CODE_SUCCESS',
    FAILURE: 'PHONE_CODE_FAILURE',
    UPDATE: 'PHONE_CODE_UPDATE',
  },
  COUNTRY: {
    PENDING: 'COUNTRY_PENDING',
    SUCCESS: 'COUNTRY_SUCCESS',
    FAILURE: 'COUNTRY_FAILURE',
    UPDATE: 'COUNTRY_UPDATE',
  },
  STATE_OPTIONS: {
    PENDING: 'STATE_OPTIONS_PENDING',
    SUCCESS: 'STATE_OPTIONS_SUCCESS',
    FAILURE: 'STATE_OPTIONS_FAILURE',
    UPDATE: 'STATE_OPTIONS_UPDATE',
  },
  OFFICE_NUM: {
    PENDING: 'OFFICE_NUM_PENDING',
    SUCCESS: 'OFFICE_NUM_SUCCESS',
    FAILURE: 'OFFICE_NUM_FAILURE',
    UPDATE: 'OFFICE_NUM_UPDATE',
  },
  COMPANY_ADDRESS: {
    PENDING: 'COMPANY_ADDRESS_PENDING',
    SUCCESS: 'COMPANY_ADDRESS_SUCCESS',
    FAILURE: 'COMPANY_ADDRESS_FAILURE',
    UPDATE: 'COMPANY_ADDRESS_UPDATE',
  },
  FORM_GETID: {
    PENDING: 'FORM_GETID_PENDING',
    SUCCESS: 'FORM_GETID_SUCCESS',
    FAILURE: 'FORM_GETID_FAILURE',
    UPDATE: 'FORM_GETID_UPDATE',
  },
  USERINFO_SUBMIT: {
    PENDING: 'USERINFO_SUBMIT_PENDING',
    SUCCESS: 'USERINFO_SUBMIT_SUCCESS',
    FAILURE: 'USERINFO_SUBMIT_FAILURE',
    UPDATE: 'USERINFO_SUBMIT_UPDATE',
  },
  FORM_SAVE: {
    PENDING: 'FORM_SAVE_PENDING',
    SUCCESS: 'FORM_SAVE_SUCCESS',
    FAILURE: 'FORM_SAVE_FAILURE',
    UPDATE: 'FORM_SAVE_UPDATE',
  },
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  UPLOAD_IC_FILE: {
    PENDING: 'UPLOAD_IC_FILE_PENDING',
    SUCCESS: 'UPLOAD_IC_FILE_SUCCESS',
    FAILURE: 'UPLOAD_IC_FILE_FAILURE',
    UPDATE: 'UPLOAD_IC_FILE_UPDATE',
  },
  UPLOAD_IC_FILE_BACK: {
    PENDING: 'UPLOAD_IC_FILE_BACK_PENDING',
    SUCCESS: 'UPLOAD_IC_FILE_BACK_SUCCESS',
    FAILURE: 'UPLOAD_IC_FILE_BACK_FAILURE',
    UPDATE: 'UPLOAD_IC_FILE_BACK_UPDATE',
  },
  UPLOAD_CIDB_FILE: {
    PENDING: 'UPLOAD_CIDB_FILE_PENDING',
    SUCCESS: 'UPLOAD_CIDB_FILE_SUCCESS',
    FAILURE: 'UPLOAD_CIDB_FILE_FAILURE',
    UPDATE: 'UPLOAD_CIDB_FILE_UPDATE',
  },
  UPLOAD_ADDITIONAL_DOCUMENT_FILE: {
    PENDING: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_PENDING',
    SUCCESS: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_SUCCESS',
    FAILURE: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_FAILURE',
    UPDATE: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_UPDATE',
  },
  UPLOAD_PROFILE_PHOTO: {
    PENDING: 'UPLOAD_PROFILE_PHOTO_PENDING',
    SUCCESS: 'UPLOAD_PROFILE_PHOTO_SUCCESS',
    FAILURE: 'UPLOAD_PROFILE_PHOTO_FAILURE',
    UPDATE: 'UPLOAD_PROFILE_PHOTO_UPDATE',
  },
  UPLOAD_PASSPORT_FILE: {
    PENDING: 'UPLOAD_PASSPORT_FILE_PENDING',
    SUCCESS: 'UPLOAD_PASSPORT_FILE_SUCCESS',
    FAILURE: 'UPLOAD_PASSPORT_FILE_FAILURE',
    UPDATE: 'UPLOAD_PASSPORT_FILE_UPDATE',
  },
  UPLOAD_WORK_PERMIT: {
    PENDING: 'UPLOAD_WORK_PERMIT_PENDING',
    SUCCESS: 'UPLOAD_WORK_PERMIT_SUCCESS',
    FAILURE: 'UPLOAD_WORK_PERMIT_FAILURE',
    UPDATE: 'UPLOAD_WORK_PERMIT_UPDATE',
  },
  UPLOAD_COMPANY_OFFER_LETTER: {
    PENDING: 'UPLOAD_COMPANY_OFFER_LETTER_PENDING',
    SUCCESS: 'UPLOAD_COMPANY_OFFER_LETTER_SUCCESS',
    FAILURE: 'UPLOAD_COMPANY_OFFER_LETTER_FAILURE',
    UPDATE: 'UPLOAD_COMPANY_OFFER_LETTER_UPDATE',
  },
  UPLOAD_RELEASE_LETTER: {
    PENDING: 'UPLOAD_RELEASE_LETTER_PENDING',
    SUCCESS: 'UPLOAD_RELEASE_LETTER_SUCCESS',
    FAILURE: 'UPLOAD_RELEASE_LETTER_FAILURE',
    UPDATE: 'UPLOAD_RELEASE_LETTER_UPDATE',
  },
  NATIONALITY_FOREIGN: {
    PENDING: 'NATIONALITY_FOREIGN_PENDING',
    SUCCESS: 'NATIONALITY_FOREIGN_SUCCESS',
    FAILURE: 'NATIONALITY_FOREIGN_FAILURE',
    UPDATE: 'NATIONALITY_FOREIGN_UPDATE',
  },
  APP_FORM_VALIDATION: {
    PENDING: 'APP_FORM_VALIDATION_PENDING',
    SUCCESS: 'APP_FORM_VALIDATION_SUCCESS',
    FAILURE: 'APP_FORM_VALIDATION_FAILURE',
    UPDATE: 'APP_FORM_VALIDATION_UPDATE',
    CLEAR: 'APP_FORM_VALIDATION_CLEAR',
  },
  BODY_PASS_INFO: {
    PENDING: 'BODY_PASS_INFO_PENDING',
    SUCCESS: 'BODY_PASS_INFO_SUCCESS',
    FAILURE: 'BODY_PASS_INFO_FAILURE',
    UPDATE: 'BODY_PASS_INFO_UPDATE',
    CLEAR: 'BODY_PASS_INFO_CLEAR',
  },
  CLEAR_ALL_FORM: 'CLEAR_ALL_FORM',
}

export const USERINFO_STATE = {
  REFRESHED: 'USERINFO_REFRESHED',
  IN_PROGRESS: 'USERINFO_PROGRESS',
  SUCCESS: 'USERINFO_RETRIEVED',
  ERROR: 'USERINFO_ERROR',
  PENDING: 'USERINFO_PENDING',
}
export const WORKAREA_STATE = {
  REFRESHED: 'WORKAREA_REFRESHED',
  IN_PROGRESS: 'WORKAREA_PROGRESS',
  SUCCESS: 'WORKAREA_RETRIEVED',
  ERROR: 'WORKAREA_ERROR',
  PENDING: 'WORKAREA_PENDING',
}
export const DEPARTMENT_STATE = {
  REFRESHED: 'DEPARTMENT_REFRESHED',
  IN_PROGRESS: 'DEPARTMENT_PROGRESS',
  SUCCESS: 'DEPARTMENT_RETRIEVED',
  ERROR: 'DEPARTMENT_ERROR',
  PENDING: 'DEPARTMENT_PENDING',
  UPDATE: 'DEPARTMENT_UPDATE',
}
export const LOCATION_STATE = {
  REFRESHED: 'LOCATION_REFRESHED',
  IN_PROGRESS: 'LOCATION_PROGRESS',
  SUCCESS: 'LOCATION_RETRIEVED',
  ERROR: 'LOCATION_ERROR',
  PENDING: 'LOCATION_PENDING',
  UPDATE: 'LOCATION_UPDATE',
}
export const EMPLOYER_COMPANY_STATE = {
  REFRESHED: 'EMPLOYER_COMPANY_REFRESHED',
  IN_PROGRESS: 'EMPLOYER_COMPANY_PROGRESS',
  SUCCESS: 'EMPLOYER_COMPANY_RETRIEVED',
  ERROR: 'EMPLOYER_COMPANY_ERROR',
  PENDING: 'EMPLOYER_COMPANY_PENDING',
  UPDATE: 'EMPLOYER_COMPANY_UPDATE',
}
export const PHONE_CODE_STATE = {
  REFRESHED: 'PHONE_CODE_REFRESHED',
  IN_PROGRESS: 'PHONE_CODE_PROGRESS',
  SUCCESS: 'PHONE_CODE_RETRIEVED',
  ERROR: 'PHONE_CODE_ERROR',
  PENDING: 'PHONE_CODE_PENDING',
  UPDATE: 'PHONE_CODE_UPDATE',
}
export const COUNTRY_STATE = {
  REFRESHED: 'COUNTRY_REFRESHED',
  IN_PROGRESS: 'COUNTRY_PROGRESS',
  SUCCESS: 'COUNTRY_RETRIEVED',
  ERROR: 'COUNTRY_ERROR',
  PENDING: 'COUNTRY_PENDING',
  UPDATE: 'COUNTRY_UPDATE',
}
export const STATE_OPTIONS_STATE = {
  REFRESHED: 'STATE_OPTIONS_REFRESHED',
  IN_PROGRESS: 'STATE_OPTIONS_PROGRESS',
  SUCCESS: 'STATE_OPTIONS_RETRIEVED',
  ERROR: 'STATE_OPTIONS_ERROR',
  PENDING: 'STATE_OPTIONS_PENDING',
  UPDATE: 'STATE_OPTIONS_UPDATE',
}
export const OFFICE_NUM_STATE = {
  REFRESHED: 'OFFICE_NUM_REFRESHED',
  IN_PROGRESS: 'OFFICE_NUM_PROGRESS',
  SUCCESS: 'OFFICE_NUM_RETRIEVED',
  ERROR: 'OFFICE_NUM_ERROR',
  PENDING: 'OFFICE_NUM_PENDING',
  UPDATE: 'OFFICE_NUM_UPDATE',
}
export const COMPANY_ADDRESS_STATE = {
  REFRESHED: 'COMPANY_ADDRESS_REFRESHED',
  IN_PROGRESS: 'COMPANY_ADDRESS_PROGRESS',
  SUCCESS: 'COMPANY_ADDRESS_RETRIEVED',
  ERROR: 'COMPANY_ADDRESS_ERROR',
  PENDING: 'COMPANY_ADDRESS_PENDING',
  UPDATE: 'COMPANY_ADDRESS_UPDATE',
}
export const FORM_GETID_STATE = {
  REFRESHED: 'FORM_GETID_REFRESHED',
  IN_PROGRESS: 'FORM_GETID_PROGRESS',
  SUCCESS: 'FORM_GETID_RETRIEVED',
  ERROR: 'FORM_GETID_ERROR',
  PENDING: 'FORM_GETID_PENDING',
}
export const FORM_SAVE_STATE = {
  REFRESHED: 'FORM_SAVE_REFRESHED',
  IN_PROGRESS: 'FORM_SAVE_PROGRESS',
  SUCCESS: 'FORM_SAVE_RETRIEVED',
  ERROR: 'FORM_SAVE_ERROR',
  PENDING: 'FORM_SAVE_PENDING',
}
export const UPDATE_FORM_DATA_STATE = {
  REFRESHED: 'UPDATE_FORM_DATA_REFRESHED',
  IN_PROGRESS: 'UPDATE_FORM_DATA_PROGRESS',
  SUCCESS: 'UPDATE_FORM_DATA_RETRIEVED',
  ERROR: 'UPDATE_FORM_DATA_ERROR',
  PENDING: 'UPDATE_FORM_DATA_PENDING',
}
export const UPLOAD_IC_FILE_STATE = {
  REFRESHED: 'UPLOAD_IC_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_IC_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_IC_FILE_RETRIEVED',
  ERROR: 'UPLOAD_IC_FILE_ERROR',
  PENDING: 'UPLOAD_IC_FILE_PENDING',
}
export const UPLOAD_IC_FILE_BACK_STATE = {
  REFRESHED: 'UPLOAD_IC_FILE_BACK_REFRESHED',
  IN_PROGRESS: 'UPLOAD_IC_FILE_BACK_PROGRESS',
  SUCCESS: 'UPLOAD_IC_FILE_BACK_RETRIEVED',
  ERROR: 'UPLOAD_IC_FILE_BACK_ERROR',
  PENDING: 'UPLOAD_IC_FILE_BACK_PENDING',
}
export const UPLOAD_CIDB_FILE_STATE = {
  REFRESHED: 'UPLOAD_CIDB_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_CIDB_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_CIDB_FILE_RETRIEVED',
  ERROR: 'UPLOAD_CIDB_FILE_ERROR',
  PENDING: 'UPLOAD_CIDB_FILE_PENDING',
}
export const UPLOAD_ADDITIONAL_DOCUMENT_FILE_STATE = {
  REFRESHED: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_RETRIEVED',
  ERROR: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_ERROR',
  PENDING: 'UPLOAD_ADDITIONAL_DOCUMENT_FILE_PENDING',
}
export const UPLOAD_PROFILE_PHOTO_STATE = {
  REFRESHED: 'UPLOAD_PROFILE_PHOTO_REFRESHED',
  IN_PROGRESS: 'UPLOAD_PROFILE_PHOTO_PROGRESS',
  SUCCESS: 'UPLOAD_PROFILE_PHOTO_RETRIEVED',
  ERROR: 'UPLOAD_PROFILE_PHOTO_ERROR',
  PENDING: 'UPLOAD_PROFILE_PHOTO_PENDING',
}
export const UPLOAD_PASSPORT_FILE_STATE = {
  REFRESHED: 'UPLOAD_PASSPORT_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_PASSPORT_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_PASSPORT_FILE_RETRIEVED',
  ERROR: 'UPLOAD_PASSPORT_FILE_ERROR',
  PENDING: 'UPLOAD_PASSPORT_FILE_PENDING',
}
export const UPLOAD_WORK_PERMIT_STATE = {
  REFRESHED: 'UPLOAD_WORK_PERMIT_REFRESHED',
  IN_PROGRESS: 'UPLOAD_WORK_PERMIT_PROGRESS',
  SUCCESS: 'UPLOAD_WORK_PERMIT_RETRIEVED',
  ERROR: 'UPLOAD_WORK_PERMIT_ERROR',
  PENDING: 'UPLOAD_WORK_PERMIT_PENDING',
}
export const UPLOAD_COMPANY_OFFER_LETTER_STATE = {
  REFRESHED: 'UPLOAD_COMPANY_OFFER_LETTER_REFRESHED',
  IN_PROGRESS: 'UPLOAD_COMPANY_OFFER_LETTER_PROGRESS',
  SUCCESS: 'UPLOAD_COMPANY_OFFER_LETTER_RETRIEVED',
  ERROR: 'UPLOAD_COMPANY_OFFER_LETTER_ERROR',
  PENDING: 'UPLOAD_COMPANY_OFFER_LETTER_PENDING',
}
export const UPLOAD_RELEASE_LETTER_STATE = {
  REFRESHED: 'UPLOAD_RELEASE_LETTER_REFRESHED',
  IN_PROGRESS: 'UPLOAD_RELEASE_LETTER_PROGRESS',
  SUCCESS: 'UPLOAD_RELEASE_LETTER_RETRIEVED',
  ERROR: 'UPLOAD_RELEASE_LETTER_ERROR',
  PENDING: 'UPLOAD_RELEASE_LETTER_PENDING',
}
export const USERINFO_SUBMIT_STATE = {
  REFRESHED: 'USERINFO_SUBMIT_REFRESHED',
  IN_PROGRESS: 'USERINFO_SUBMIT_PROGRESS',
  SUCCESS: 'USERINFO_SUBMIT_RETRIEVED',
  ERROR: 'USERINFO_SUBMIT_ERROR',
  PENDING: 'USERINFO_SUBMIT_PENDING',
}
export const NATIONALITY_FOREIGN_STATE = {
  REFRESHED: 'NATIONALITY_FOREIGN_REFRESHED',
  IN_PROGRESS: 'NATIONALITY_FOREIGN_PROGRESS',
  SUCCESS: 'NATIONALITY_FOREIGN_RETRIEVED',
  ERROR: 'NATIONALITY_FOREIGN_ERROR',
  PENDING: 'NATIONALITY_FOREIGN_PENDING',
}
export const BODY_PASS_INFO_STATE = {
  REFRESHED: 'BODY_PASS_INFO_REFRESHED',
  IN_PROGRESS: 'BODY_PASS_INFO_PROGRESS',
  SUCCESS: 'BODY_PASS_INFO_RETRIEVED',
  ERROR: 'BODY_PASS_INFO_ERROR',
  PENDING: 'BODY_PASS_INFO_PENDING',
}

export default BODYPASS_ACTIONS
