const NEW_REGISTRATION_ACTIONS = {
  FORM_SAVE: {
    PENDING: 'FORM_SAVE_PENDING',
    SUCCESS: 'FORM_SAVE_SUCCESS',
    FAILURE: 'FORM_SAVE_FAILURE',
    UPDATE: 'FORM_SAVE_UPDATE',
  },
  FORM_GETID: {
    PENDING: 'FORM_GETID_PENDING',
    SUCCESS: 'FORM_GETID_SUCCESS',
    FAILURE: 'FORM_GETID_FAILURE',
    UPDATE: 'FORM_GETID_UPDATE',
  },
  UPLOAD_ROADTAX_FILE: {
    PENDING: 'UPLOAD_ROADTAX_FILE_PENDING',
    SUCCESS: 'UPLOAD_ROADTAX_FILE_SUCCESS',
    FAILURE: 'UPLOAD_ROADTAX_FILE_FAILURE',
    UPDATE: 'UPLOAD_ROADTAX_FILE_UPDATE',
  },
  UPLOAD_DRIVINGLICENSE_FILE: {
    PENDING: 'UPLOAD_DRIVINGLICENSE_FILE_PENDING',
    SUCCESS: 'UPLOAD_DRIVINGLICENSE_FILE_SUCCESS',
    FAILURE: 'UPLOAD_DRIVINGLICENSE_FILE_FAILURE',
    UPDATE: 'UPLOAD_DRIVINGLICENSE_FILE_UPDATE',
  },
  UPLOAD_DRIVINGLICENSEBACK_FILE: {
    PENDING: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PENDING',
    SUCCESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_SUCCESS',
    FAILURE: 'UPLOAD_DRIVINGLICENSEBACK_FILE_FAILURE',
    UPDATE: 'UPLOAD_DRIVINGLICENSEBACK_FILE_UPDATE',
  },
  UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE: {
    PENDING: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PENDING',
    SUCCESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_SUCCESS',
    FAILURE: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_FAILURE',
    UPDATE: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_UPDATE',
  },
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  GET_VEHICLE_TYPE_DATA: {
    PENDING: 'GET_VEHICLE_TYPE_DATA_PENDING',
    SUCCESS: 'GET_VEHICLE_TYPE_DATA_SUCCESS',
    FAILURE: 'GET_VEHICLE_TYPE_DATA_FAILURE',
    UPDATE: 'GET_VEHICLE_TYPE_DATA_UPDATE',
  },
  GET_LICENSE_CLASS_DATA: {
    PENDING: 'GET_LICENSE_CLASS_DATA_PENDING',
    SUCCESS: 'GET_LICENSE_CLASS_DATA_SUCCESS',
    FAILURE: 'GET_LICENSE_CLASS_DATA_FAILURE',
    UPDATE: 'GET_LICENSE_CLASS_DATA_UPDATE',
  },
  APP_FORM_VALIDATION: {
    PENDING: 'APP_FORM_VEHICLE_VALIDATION_PENDING',
    SUCCESS: 'APP_FORM_VEHICLE_VALIDATION_SUCCESS',
    FAILURE: 'APP_FORM_VEHICLE_VALIDATION_FAILURE',
    UPDATE: 'APP_FORM_VEHICLE_VALIDATION_UPDATE',
    CLEAR: 'APP_FORM_VEHICLE_VALIDATION_CLEAR',
  },
  LICENSE_CLASS_LIST: {
    PENDING: 'LICENSE_CLASS_LIST_PENDING',
    SUCCESS: 'LICENSE_CLASS_LIST_SUCCESS',
    FAILURE: 'LICENSE_CLASS_LIST_FAILURE',
    UPDATE: 'LICENSE_CLASS_LIST_UPDATE',
    CLEAR: 'LICENSE_CLASS_LIST_CLEAR',
  },
  CLEAR_ALL_FORM: 'CLEAR_ALL_FORM',
}

export const FORM_SAVE_STATE = {
  REFRESHED: 'FORM_SAVE_REFRESHED',
  IN_PROGRESS: 'FORM_SAVE_PROGRESS',
  SUCCESS: 'FORM_SAVE_RETRIEVED',
  ERROR: 'FORM_SAVE_ERROR',
  PENDING: 'FORM_SAVE_PENDING',
}

export const FORM_GETID_STATE = {
  REFRESHED: 'FORM_GETID_REFRESHED',
  IN_PROGRESS: 'FORM_GETID_PROGRESS',
  SUCCESS: 'FORM_GETID_RETRIEVED',
  ERROR: 'FORM_GETID_ERROR',
  PENDING: 'FORM_GETID_PENDING',
}

export const UPLOAD_ROADTAX_FILE_STATE = {
  REFRESHED: 'UPLOAD_ROADTAX_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_ROADTAX_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_ROADTAX_FILE_RETRIEVED',
  ERROR: 'UPLOAD_ROADTAX_FILE_ERROR',
  PENDING: 'UPLOAD_ROADTAX_FILE_PENDING',
}

export const UPLOAD_DRIVINGLICENSE_FILE_STATE = {
  REFRESHED: 'UPLOAD_DRIVINGLICENSE_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_DRIVINGLICENSE_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_DRIVINGLICENSE_FILE_RETRIEVED',
  ERROR: 'UPLOAD_DRIVINGLICENSE_FILE_ERROR',
  PENDING: 'UPLOAD_DRIVINGLICENSE_FILE_PENDING',
}

export const UPLOAD_DRIVINGLICENSEBACK_FILE_STATE = {
  REFRESHED: 'UPLOAD_DRIVINGLICENSEBACK_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_RETRIEVED',
  ERROR: 'UPLOAD_DRIVINGLICENSEBACK_FILE_ERROR',
  PENDING: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PENDING',
}

export const UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE = {
  REFRESHED: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_RETRIEVED',
  ERROR: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_ERROR',
  PENDING: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PENDING',
}

export const GET_VEHICLE_TYPE_DATA_STATE = {
  REFRESHED: 'GET_VEHICLE_TYPE_DATA_REFRESHED',
  IN_PROGRESS: 'GET_VEHICLE_TYPE_DATA_PROGRESS',
  SUCCESS: 'GET_VEHICLE_TYPE_DATA_RETRIEVED',
  ERROR: 'GET_VEHICLE_TYPE_DATA_ERROR',
  PENDING: 'GET_VEHICLE_TYPE_DATA_PENDING',
}
export const LICENSE_CLASS_LIST_STATE = {
  REFRESHED: 'LICENSE_CLASS_LIST_REFRESHED',
  IN_PROGRESS: 'LICENSE_CLASS_LIST_PROGRESS',
  SUCCESS: 'LICENSE_CLASS_LIST_RETRIEVED',
  ERROR: 'LICENSE_CLASS_LIST_ERROR',
  PENDING: 'LICENSE_CLASS_LIST_PENDING',
}

export const GET_LICENSE_CLASS_DATA_STATE = {
  REFRESHED: 'GET_LICENSE_CLASS_DATA_REFRESHED',
  IN_PROGRESS: 'GET_LICENSE_CLASS_DATA_PROGRESS',
  SUCCESS: 'GET_LICENSE_CLASS_DATA_RETRIEVED',
  ERROR: 'GET_LICENSE_CLASS_DATA_ERROR',
  PENDING: 'GET_LICENSE_CLASS_DATA_PENDING',
}

export default NEW_REGISTRATION_ACTIONS
