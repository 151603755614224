import ACTION, { USER_PROFILE_STATE, FORM_SAVE_STATE } from './creator'

const init_userProfile = {
  payload: {},
  status: USER_PROFILE_STATE.PENDING,
  message: '',
}

const init_update = {
  fullName: '',
  icNum: '',
  file: '',
  age: '',
  fileName: '',
  contactNum: '',
  reason: '',
  race: '',
  gender: '',
  nationality: '',
  occupation: '',
  birthOfDate: '',
  employersCompany: [],
  workingCompany: '',
  residentialAddress: '',
  companyAddress: '',
  officeNum: '',
  station: 'POLIS PELABUHAN',
  reportNum: '',
  dateOfReport: '',
  timeOfReport: '',
  languageUsed: '',
  officer: '',
  officerIcNum: '',
  policeId: '',
  translatorName: '',
  passportNum: '',
  originLanguage: '',
  reasons: '',
  complainantStatement: '',
  summaryOfAction: '',
  referenceId: '',
  tenantId: '',
  userId: '',
  tableItems: [],
}
const init_next = {
  status: FORM_SAVE_STATE.PENDING,
  message: '',
}
const init_appFormValidation = {
  status: '',
  message: '',
  data: {
    fullNameValid: false,
    icNumValid: false,
    fileValid: false,
    genderValid: false,
    raceValid: false,
    nationalityValid: false,
    birthOfDateValid: null,
    ageValid: false,
    employersCompanyValid: false,
    contactNumValid: false,
    residentialAddressValid: false,
    workingCompanyValid: false,
    companyAddressValid: false,
    officeNumValid: false,
    stationValid: false,
    reportNumValid: false,
    dateOfReportValid: null,
    timeOfReportValid: null,
    languageUsedValid: false,
    officerValid: false,
    officerIcNumValid: false,
    policeIdValid: false,
    translatorNameValid: false,
    passportNumValid: false,
    originLanguageValid: false,
    reasonsValid: false,
    complainantStatementValid: false,
  },
}

export const userLostCardProfileData = (state = init_userProfile, { type, payload }) => {
  switch (type) {
    case ACTION.USER_PROFILE.PENDING:
    case ACTION.USER_PROFILE.SUCCESS:
      return { ...state, ...payload }
    case ACTION.USER_PROFILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

export const updateLostCardFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
      return init_update
    default:
      return state
  }
}
export const nextAddNewReportData = (state = init_next, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SAVE.PENDING:
    case ACTION.FORM_SAVE.FAILURE:
    case ACTION.FORM_SAVE.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const lostCardNewReportFormValidation = (
  state = init_appFormValidation,
  { type, payload },
) => {
  switch (type) {
    case ACTION.APP_FORM_VALIDATION.SUCCESS:
      return { ...state, data: { ...state.data, ...payload } }
    case ACTION.APP_FORM_VALIDATION.CLEAR:
      return init_appFormValidation
    default:
      return state
  }
}
