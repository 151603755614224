import ACTION, { HISTORY_STATE } from './creator'

const init = {
  status: HISTORY_STATE.PENDING,
  message: '',
  historyData: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}

export const tenantHistoryTableData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.HISTORY.PENDING:
    case ACTION.HISTORY.SUCCESS:
      return { ...state, ...payload }
    case ACTION.HISTORY.FAILURE:
      return { ...state, ...payload, historyData: init.historyData }
    case ACTION.HISTORY.UPDATE_LIST:
      // payload = {id: , status: Approved|Rejected}
      if (!payload.id) {
        return
      }
      const findIndex = state.historyData.list.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        const data = { ...state.historyData.list[findIndex], ...payload }
        state.historyData.list[findIndex] = data
      }

      return { ...state }
    default:
      return state
  }
}
