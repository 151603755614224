import ACTION, { PAYMENT_HISTORY_STATE, RECEIPT_STATE } from './creator'

const init = {
  status: PAYMENT_HISTORY_STATE.PENDING,
  message: '',
  paymentHistoryData: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}

const initReceipt = {
  status: RECEIPT_STATE.PENDING,
  message: '',
  receiptData: {
    list: [],
    paymentMethod: '',
    paymentFrom: '',
    paymentFor: '',
    sstId: '',
    receiptId: '',
    date: '',
    amountExculeSst: 0,
    sstAmount: 0,
    amountIncludeSst: 0,
    roundingAdjustment: 0,
    netAmount: 0,
  },
}

export const userPaymentHistoryTableDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.PAYMENT_HISTORY.PENDING:
    case ACTION.PAYMENT_HISTORY.SUCCESS:
      return { ...state, ...payload }
    case ACTION.PAYMENT_HISTORY.FAILURE:
      return { ...state, ...payload, paymentHistoryData: init.paymentHistoryData }
    case ACTION.PAYMENT_HISTORY.UPDATE_LIST:
      // payload = {id: , status: Approved|Rejected}
      if (!payload.id) {
        return
      }
      const findIndex = state.paymentHistoryData.list.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        state.paymentHistoryData.list[findIndex].statusApplication = payload.status
      }

      return { ...state }
    default:
      return state
  }
}

export const userReceiptData = (state = initReceipt, { type, payload }) => {
  switch (type) {
    case ACTION.RECEIPT.PENDING:
    case ACTION.RECEIPT.SUCCESS:
      return { ...state, ...payload }
    case ACTION.RECEIPT.FAILURE:
      return { ...state, ...payload, receiptData: initReceipt.receiptData }
    default:
      return state
  }
}
