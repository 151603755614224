//import React from 'react'
import ACTION, {
  USERINFO_STATE,
  USERINFO_SUBMIT_STATE,
  WORKAREA_STATE,
  DEPARTMENT_STATE,
  LOCATION_STATE,
  EMPLOYER_COMPANY_STATE,
  PHONE_CODE_STATE,
  COUNTRY_STATE,
  FORM_GETID_STATE,
  STATE_OPTIONS_STATE,
  OFFICE_NUM_STATE,
  COMPANY_ADDRESS_STATE,
  FORM_SAVE_STATE,
  UPLOAD_IC_FILE_STATE,
  UPLOAD_IC_FILE_BACK_STATE,
  UPLOAD_CIDB_FILE_STATE,
  UPLOAD_PROFILE_PHOTO_STATE,
  UPLOAD_PASSPORT_FILE_STATE,
  UPLOAD_WORK_PERMIT_STATE,
  UPLOAD_COMPANY_OFFER_LETTER_STATE,
  UPLOAD_RELEASE_LETTER_STATE,
  NATIONALITY_FOREIGN_STATE,
  BODY_PASS_INFO_STATE,
  UPLOAD_ADDITIONAL_DOCUMENT_FILE_STATE,
} from './creator'

const init = {
  status: USERINFO_STATE.PENDING,
  message: '',
  userInfo: {},
}
const init_workArea = {
  status: WORKAREA_STATE.PENDING,
  message: '',
  data: [],
}
const init_department = {
  status: DEPARTMENT_STATE.PENDING,
  message: '',
  data: [],
  dapartment: [],
}
const init_officeNo = {
  status: OFFICE_NUM_STATE.PENDING,
  message: '',
  data: [],
  officeNo: '',
}
const init_compAddress = {
  status: COMPANY_ADDRESS_STATE.PENDING,
  message: '',
  data: [],
  companyAddress: '',
}
const init_stateOptions = {
  status: STATE_OPTIONS_STATE.PENDING,
  message: '',
  data: [],
  stateOptions: [],
}
const init_location = {
  status: LOCATION_STATE.PENDING,
  message: '',
  data: [],
}
const init_company = {
  status: EMPLOYER_COMPANY_STATE.PENDING,
  message: '',
  data: [],
}
const init_phoneCode = {
  status: PHONE_CODE_STATE.PENDING,
  message: '',
  data: [],
}
const init_country = {
  status: COUNTRY_STATE.PENDING,
  message: '',
  data: [],
}
const init_formId = {
  status: FORM_GETID_STATE.PENDING,
  message: '',
}
const init_save = {
  status: FORM_SAVE_STATE.PENDING,
  message: '',
}
const init_update = {
  email: '',
  fullName: '',
  oldPassportNum: '',
  newPassportNum: '',
  newPassportExpDate: '',
  permitWorkExpired: '',
  nationality: '',
  icNumber: '',
  contactNoCode: '',
  contactNo: '',
  residentialAddress: '',
  city: '',
  postcode: '',
  company: '',
  location: [],
  otherWorkLocation: '',
  gender: '',
  paymentMethod: [
    {
      id: '2',
      value: 'Cash',
      label: 'Cash / Tunai',
      checked: true,
      disabled: false,
    },
    {
      id: '3',
      value: 'Online',
      label: 'Online / Atas Talian',
      checked: false,
      disabled: false,
    },
    {
      id: '1',
      value: 'Company',
      label: 'Company / Syarikat',
      checked: false,
      disabled: true,
    },
  ],
  duration: [
    {
      id: '1',
      value: 'month',
      label: 'Monthly Pass (1 Month) - RM 10',
      total: 10,
    },
    {
      id: '3',
      value: 'month2',
      label: 'Monthly Pass (2 Month) - RM 20',
      total: 20,
    },
    {
      id: '2',
      value: 'year',
      label: 'Yearly Pass (1 Year) - RM 60',
      total: 60,
    },
  ],
  renewDuration: [
    {
      id: '1',
      value: 'month',
      label: 'Monthly Pass (1 Month) - RM 10',
      total: 10,
    },
    {
      id: '3',
      value: 'month2',
      label: 'Monthly Pass (2 Month) - RM 20',
      total: 20,
    },
    {
      id: '2',
      value: 'year',
      label: 'Yearly Pass (1 Year) - RM 15',
      total: 15,
    },
  ],
  startDate: '',
  endDate: '',
  occupation: '',
  nextPage: false,
  newReg: false,
  formIdResponse: '',
  icFile: '',
  passportFile: '',
  wPermitFile: '',
  offerLetterFile: '',
  releaseLetterFile: '',
  vPassOptions: [
    {
      id: '1',
      label: 'Yes / Ya',
      value: 'yes',
      checked: false,
    },
    {
      id: '2',
      label: 'No / Tidak',
      value: 'no',
      checked: false,
    },
  ],
  profilePhotoImage: '',
  personInCharge: '',
  safetyBrief: false,
}
const init_submit = {
  status: USERINFO_SUBMIT_STATE.PENDING,
  message: '',
}
const init_ic = {
  status: UPLOAD_IC_FILE_STATE.PENDING,
  message: '',
}
const init_icBack = {
  status: UPLOAD_IC_FILE_BACK_STATE.PENDING,
  message: '',
}
const init_cidb = {
  status: UPLOAD_CIDB_FILE_STATE.PENDING,
  message: '',
}
const init_addDoc = {
  status: UPLOAD_ADDITIONAL_DOCUMENT_FILE_STATE.PENDING,
  message: '',
}
const init_profilePhoto = {
  status: UPLOAD_PROFILE_PHOTO_STATE.PENDING,
  message: '',
}
const init_passportFile = {
  status: UPLOAD_PASSPORT_FILE_STATE.PENDING,
  message: '',
}
const init_workPermit = {
  status: UPLOAD_WORK_PERMIT_STATE.PENDING,
  message: '',
}
const init_offerLetter = {
  status: UPLOAD_COMPANY_OFFER_LETTER_STATE.PENDING,
  message: '',
}
const init_releaseLetter = {
  status: UPLOAD_RELEASE_LETTER_STATE.PENDING,
  message: '',
}
const init_nationalityForeign = {
  status: NATIONALITY_FOREIGN_STATE.PENDING,
  message: '',
  data: [],
}
const init_appFormValidation = {
  status: '',
  message: '',
  data: {
    // workDescriptionValid: false,
    wPassOptionsValid: false,
    vPassOptionsValid: false,
    paymentOptionsValid: false,
    wPassStartDateValid: null,
    regNoValid: false,
    typeOfVehicleValid: false,
    roadTaxExpiredDateValid: null,
    licenseExpiredDateValid: null,
    paymentMethodValid: false,
    icFileValid: false,
    roadTaxValid: false,
    drivingLicenseValid: false,
    additionalDrivingLicenseValid: false,
    newPassportNumValid: null,
    newPassportExpDateValid: null,
    passportFileValid: false,
    wPermitFileValid: false,
    offerLetterFileValid: false,
    releaseLetterFileValid: false,
    workingCompanyNewValid: false,
    departmentNewValid: false,
  },
}
const init_bodyPassInfo = {
  status: BODY_PASS_INFO_STATE.PENDING,
  message: '',
  data: {},
}

export const bodyPassData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.USERINFO.PENDING:
    case ACTION.USERINFO.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        userInfo: {
          fullName: payload.user.fullname,
          nationality: payload.user.nationality,
          email: payload.user.email,
          icNum: payload.user.icNumber,
          oldPassportNum: payload.user.oldPassport,
          newPassportNum: payload.user.newPassport,
          contactNo: payload.user.contactNo,
          contactNoCode: payload.user.contactNoCode,
          employerCompany: payload.user.employerCompany,
          country: payload.user.country,
          state: payload.user.state,
        },
      }
    case ACTION.USERINFO.FAILURE:
      return { ...state, ...bodyPassData }

    case ACTION.CLEAR_ALL_FORM:
      return init
    default:
      return state
  }
}
export const phoneCodeData = (state = init_phoneCode, { type, payload }) => {
  switch (type) {
    case ACTION.PHONE_CODE.PENDING:
    case ACTION.PHONE_CODE.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.PHONE_CODE.FAILURE:
      return { ...state, ...phoneCodeData }

    case ACTION.CLEAR_ALL_FORM:
      return init_phoneCode
    default:
      return state
  }
}
export const countryData = (state = init_country, { type, payload }) => {
  switch (type) {
    case ACTION.COUNTRY.PENDING:
    case ACTION.COUNTRY.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.COUNTRY.FAILURE:
      return { ...state, ...countryData }

    case ACTION.CLEAR_ALL_FORM:
      return init_country
    default:
      return state
  }
}
export const workAreaData = (state = init_workArea, { type, payload }) => {
  switch (type) {
    case ACTION.WORKAREA.PENDING:
    case ACTION.WORKAREA.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.WORKAREA.FAILURE:
      return { ...state, ...workAreaData }

    case ACTION.CLEAR_ALL_FORM:
      return init_workArea
    default:
      return state
  }
}
export const departmentData = (state = init_department, { type, departmentPayload }) => {
  switch (type) {
    case ACTION.DEPARTMENT.PENDING:
    case ACTION.DEPARTMENT.SUCCESS:
      return {
        ...state,
        dapartment: [],
        data: departmentPayload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.DEPARTMENT.UPDATE:
      let departmentRes = []
      const matchingItem = state.data.data.find(
        (item) => item.name === departmentPayload.workingCompany,
      )
      if (matchingItem && matchingItem.organizationDepartments) {
        departmentRes = matchingItem.organizationDepartments
      } else {
        departmentRes = []
      }
      return {
        ...state,
        dapartment: departmentRes, // This should be payload.data instead of payload.user
      }
    case ACTION.DEPARTMENT.FAILURE:
      return { ...state, ...departmentPayload }
    case ACTION.CLEAR_ALL_FORM:
      return init_department
    default:
      return state
  }
}
export const officeData = (state = init_officeNo, { type, officeNumPayload }) => {
  switch (type) {
    case ACTION.OFFICE_NUM.PENDING:
    case ACTION.OFFICE_NUM.SUCCESS:
      return {
        ...state,
        officeNo: '',
        data: officeNumPayload, // This should be payload.data instead of payload.user
      }
    case ACTION.OFFICE_NUM.UPDATE:
      let officeNoRes = ''
      const matchingItem = state.data.data.find(
        (item) => item.name === officeNumPayload.workingCompany,
      )
      if (matchingItem && matchingItem.phoneNo) {
        officeNoRes = matchingItem.phoneNo
      } else {
        officeNoRes = ''
      }
      return {
        ...state,
        officeNo: officeNoRes, // This should be payload.data instead of payload.user
      }
    case ACTION.OFFICE_NUM.FAILURE:
      return { ...state, ...officeNumPayload }
    case ACTION.CLEAR_ALL_FORM:
      return init_officeNo
    default:
      return state
  }
}
export const compAddressData = (state = init_compAddress, { type, companyAddressPayload }) => {
  switch (type) {
    case ACTION.COMPANY_ADDRESS.PENDING:
    case ACTION.COMPANY_ADDRESS.SUCCESS:
      return {
        ...state,
        companyAddress: '',
        data: companyAddressPayload, // This should be payload.data instead of payload.user
      }
    case ACTION.COMPANY_ADDRESS.UPDATE:
      let compAddressRes = ''
      const matchingItem = state.data.data.find(
        (item) => item.name === companyAddressPayload.workingCompany,
      )
      if (matchingItem && matchingItem.address) {
        compAddressRes = matchingItem.address
      } else {
        compAddressRes = ''
      }
      return {
        ...state,
        companyAddress: compAddressRes, // This should be payload.data instead of payload.user
      }
    case ACTION.COMPANY_ADDRESS.FAILURE:
      return { ...state, ...companyAddressPayload }
    default:
      return state
  }
}
export const stateOptionsData = (state = init_stateOptions, { type, stateOptionsPayload }) => {
  switch (type) {
    case ACTION.STATE_OPTIONS.PENDING:
    case ACTION.STATE_OPTIONS.SUCCESS:
    case ACTION.STATE_OPTIONS.UPDATE:
      return {
        ...state,
        status: stateOptionsPayload.status,
        message: stateOptionsPayload.message,
        data: stateOptionsPayload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.STATE_OPTIONS.FAILURE:
      return { ...state, ...stateOptionsData }
    default:
      return state
  }
}
export const locationData = (state = init_location, { type, payload }) => {
  switch (type) {
    case ACTION.LOCATION.PENDING:
    case ACTION.LOCATION.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.LOCATION.FAILURE:
      return { ...state, ...locationData }
    default:
      return state
  }
}
export const employerCompanyData = (state = init_company, { type, payload }) => {
  switch (type) {
    case ACTION.EMPLOYER_COMPANY.PENDING:
    case ACTION.EMPLOYER_COMPANY.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.LOCATION.FAILURE:
      return { ...state, ...employerCompanyData }
    default:
      return state
  }
}
export const formId = (state = init_formId, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_GETID.PENDING:
    case ACTION.FORM_GETID.SUCCESS:
    case ACTION.FORM_GETID.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const saveNewRegistrationBodyPassData = (state = init_save, { type, payload }) => {
  switch (type) {
    case ACTION.FORM_SAVE.PENDING:
    case ACTION.FORM_SAVE.SUCCESS:
    case ACTION.FORM_SAVE.FAILURE:
    case ACTION.FORM_GETID.SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const updateBodyPassFormData = (state = init_update, { type, payload }) => {
  switch (type) {
    case ACTION.UPDATE_FORM_DATA.SUCCESS:
      return { ...state, ...payload }
    case ACTION.UPDATE_FORM_DATA.CLEAR:
    case ACTION.CLEAR_ALL_FORM:
      return init_update
    default:
      return state
  }
}
export const submitData = (state = init_submit, { type, payload }) => {
  switch (type) {
    case ACTION.USERINFO_SUBMIT.PENDING:
    case ACTION.USERINFO_SUBMIT.SUCCESS:
    case ACTION.USERINFO_SUBMIT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const uploadICFile = (state = init_ic, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_IC_FILE.PENDING:
    case ACTION.UPLOAD_IC_FILE.SUCCESS:
    case ACTION.UPLOAD_IC_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_ic
    default:
      return state
  }
}
export const uploadICFileBack = (state = init_icBack, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_IC_FILE_BACK.PENDING:
    case ACTION.UPLOAD_IC_FILE_BACK.SUCCESS:
    case ACTION.UPLOAD_IC_FILE_BACK.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_icBack
    default:
      return state
  }
}
export const uploadCidbFile = (state = init_cidb, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_CIDB_FILE.PENDING:
    case ACTION.UPLOAD_CIDB_FILE.SUCCESS:
    case ACTION.UPLOAD_CIDB_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_cidb
    default:
      return state
  }
}
export const uploadAdditionalDocumentFile = (state = init_addDoc, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_ADDITIONAL_DOCUMENT_FILE.PENDING:
    case ACTION.UPLOAD_ADDITIONAL_DOCUMENT_FILE.SUCCESS:
    case ACTION.UPLOAD_ADDITIONAL_DOCUMENT_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_addDoc
    default:
      return state
  }
}
export const uploadProfilePhoto = (state = init_profilePhoto, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_PROFILE_PHOTO.PENDING:
    case ACTION.UPLOAD_PROFILE_PHOTO.SUCCESS:
    case ACTION.UPLOAD_PROFILE_PHOTO.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_profilePhoto
    default:
      return state
  }
}
export const uploadPassportFile = (state = init_passportFile, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_PASSPORT_FILE.PENDING:
    case ACTION.UPLOAD_PASSPORT_FILE.SUCCESS:
    case ACTION.UPLOAD_PASSPORT_FILE.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_passportFile
    default:
      return state
  }
}
export const uploadWorkPermit = (state = init_workPermit, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_WORK_PERMIT.PENDING:
    case ACTION.UPLOAD_WORK_PERMIT.SUCCESS:
    case ACTION.UPLOAD_WORK_PERMIT.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_workPermit
    default:
      return state
  }
}
export const uploadOfferLetter = (state = init_offerLetter, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_COMPANY_OFFER_LETTER.PENDING:
    case ACTION.UPLOAD_COMPANY_OFFER_LETTER.SUCCESS:
    case ACTION.UPLOAD_COMPANY_OFFER_LETTER.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_offerLetter
    default:
      return state
  }
}
export const uploadReleaseLetter = (state = init_releaseLetter, { type, payload }) => {
  switch (type) {
    case ACTION.UPLOAD_RELEASE_LETTER.PENDING:
    case ACTION.UPLOAD_RELEASE_LETTER.SUCCESS:
    case ACTION.UPLOAD_RELEASE_LETTER.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_releaseLetter
    default:
      return state
  }
}
export const nationalityForeignData = (state = init_nationalityForeign, { type, payload }) => {
  switch (type) {
    case ACTION.NATIONALITY_FOREIGN.PENDING:
    case ACTION.NATIONALITY_FOREIGN.SUCCESS:
      return {
        ...state,
        status: payload.status,
        message: payload.message,
        data: payload.data, // This should be payload.data instead of payload.user
      }
    case ACTION.COUNTRY.FAILURE:
      return { ...state, ...nationalityForeignData }
    default:
      return state
  }
}
export const bodyPassAppFormValidation = (state = init_appFormValidation, { type, payload }) => {
  switch (type) {
    case ACTION.APP_FORM_VALIDATION.SUCCESS:
      //return { ...state, data: { ...payload } }
      //return { ...state, ...payload }
      return { ...state, data: { ...state.data, ...payload } }
    case ACTION.APP_FORM_VALIDATION.CLEAR:
      return init_appFormValidation
    default:
      return state
  }
}
export const bodyPassInfo = (state = init_bodyPassInfo, { type, payload }) => {
  switch (type) {
    case ACTION.BODY_PASS_INFO.PENDING:
    case ACTION.BODY_PASS_INFO.SUCCESS:
    case ACTION.BODY_PASS_INFO.FAILURE:
      return { ...state, ...payload }
    case ACTION.CLEAR_ALL_FORM:
      return init_bodyPassInfo
    default:
      return state
  }
}
