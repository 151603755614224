const LOSTCARD_ACTIONS = {
  LOSTCARD: {
    PENDING: 'LOSTCARD_PENDING',
    SUCCESS: 'LOSTCARD_SUCCESS',
    FAILURE: 'LOSTCARD_FAILURE',
  },
  DELETE_LOSTCARD: {
    PENDING: 'LOSTCARD_PENDING',
    SUCCESS: 'LOSTCARD_SUCCESS',
    FAILURE: 'LOSTCARD_FAILURE',
  },
}

export const LOSTCARD_STATE = {
  REFRESHED: 'LOSTCARD_REFRESHED',
  IN_PROGRESS: 'LOSTCARD_IN_PROGRESS',
  SUCCESS: 'LOSTCARD_RETRIEVED',
  ERROR: 'LOSTCARD_ERROR',
  PENDING: 'LOSTCARD_PENDING',
}
export const DELETE_LOSTCARD_STATE = {
  REFRESHED: 'LOSTCARD_REFRESHED',
  IN_PROGRESS: 'LOSTCARD_IN_PROGRESS',
  SUCCESS: 'LOSTCARD_RETRIEVED',
  ERROR: 'LOSTCARD_ERROR',
  PENDING: 'LOSTCARD_PENDING',
}

export default LOSTCARD_ACTIONS
