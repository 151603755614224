import { combineReducers } from 'redux'

import layout from './layout/reducer'
import login from './login/reducer'
import signUp from './signup/reducer'
import submitForgotPasswordData from './password/reducer'
import submitResetPasswordData from './reset_password/reducer'
import adminLogin from './admin_login/reducer'

// DASHBOARD
import { auxDashboardDataList, apPassInfoData } from './home/dashboard/aux_police/reducer'
import { tenantDashboardDataList, tenantPassInfoData } from './home/dashboard/tenant/reducer'
import { userDashboardDataList, passInfoData } from './home/dashboard/user/reducer'
import { statusData } from './home/dashboard/user/reducer'

// CORE
import {
  tenantData,
  tenantDepartmentData,
  locationData,
  employerCompanyData,
  phoneCodeData,
  countryData,
  countryStateData,
  licenseClassData,
  vehicleTypeData,
  itemInfoData,
} from './home/core/reducer'

// USER
// import { profileData } from './home/user/profile/reducer'
import { profilePhoto } from './home/profile/reducer'
import {
  userProfileData,
  countryDataList,
  userCompany,
  companyProfileData,
} from './home/user/profile/reducer'
import {
  bodyPassData,
  submitData,
  workAreaData,
  departmentData,
  // locationData,
  // employerCompanyData,
  formId,
  // phoneCodeData,
  // countryData,
  stateOptionsData,
  officeData,
  compAddressData,
  saveNewRegistrationBodyPassData,
  updateBodyPassFormData,
  uploadICFile,
  uploadICFileBack,
  uploadCidbFile,
  uploadProfilePhoto,
  uploadPassportFile,
  uploadWorkPermit,
  uploadOfferLetter,
  uploadReleaseLetter,
  nationalityForeignData,
  bodyPassAppFormValidation,
  bodyPassInfo,
  uploadAdditionalDocumentFile,
} from './home/user/body_pass/new_registration/reducer'
import {
  updateRenewBodyPassFormData,
  renewBodyPassData,
  departmentDataRenew,
  saveRenewRegistrationBodyPassData,
  renewFormValidation,
} from './home/user/body_pass/renew_registration/reducer'
import {
  renewRegistrationBodyVehiclePassData,
  updateRenewBodyVehicleFormData,
} from './home/user/body_pass/renew_vehicle_pass/reducer'
import {
  saveNewRegistrationBodyVehiclePassData,
  uploadBodyVehicleRoadTaxFile,
  uploadBodyVehicleDrivingLicenseFile,
  uploadBodyVehicleDrivingLicenseBackFile,
  uploadBodyVehicleAdditionalDrivingLicenseFile,
  updateBodyVehicleFormData,
  getBodyVehicleTypeData,
  getBodyLicenseClassData,
} from './home/user/body_pass/vehicle_pass/reducer'
import {
  submitNewBodyPassForm,
  bodyPassSafetyBriefing,
  submitVehicleBodyPassForm,
} from './home/user/body_pass/summary/reducer'
import {
  safetyBriefingData,
  submitSafetyBriefingData,
  passPrice,
  safetyBriefingFormSubmit,
} from './home/user/safety_briefing/new_registration/reducer'
import { safetyBriefingVideoData } from './home/user/safety_briefing/safety_briefing_video/reducer'
import { paymentCartData } from './home/user/payment_cart/reducer'
import {
  saveNewRegistrationVehiclePassData,
  uploadRoadTaxFile,
  uploadDrivingLicenseFile,
  uploadDrivingLicenseBackFile,
  uploadAdditionalDrivingLicenseFile,
  updateFormData,
  getVehicleTypeData,
  vehiclePassNewRegistrationFormValidation,
  licenseClassList,
} from './home/user/vehicle_pass/new_registration/reducer'
import {
  userVehiclePassSummaryData,
  submitVehiclePassForm,
} from './home/user/vehicle_pass/summary/reducer'
import {
  updateRenewFormData,
  nextRenewRegistrationVehiclePassData,
  uploadRenewRoadTaxFile,
  uploadRenewDrivingLicenseFile,
  uploadRenewDrivingLicenseBackFile,
  uploadRenewAdditionalDrivingLicenseFile,
  getVehicle,
} from './home/user/vehicle_pass/renew_registration/reducer'
import {
  userRenewVehiclePassSummaryData,
  submitRenewVehiclePassForm,
} from './home/user/vehicle_pass/renew_registration_summary/reducer'
import { userHistoryTableData, applicationPassSummary } from './home/user/history/reducer'
import {
  userPaymentHistoryTableDataList,
  userReceiptData,
} from './home/user/history/payment_history/reducer'
import { userApplicationVerification } from 'src/modules/repositories/home/user/history/application_verification/reducer.js'

// TENANT
import { tenantBodyPassTableDataList } from './home/tenant/pending_verification/body_pass/reducer'
import { tenantVehiclePassTableDataList } from './home/tenant/pending_verification/vehicle_pass/reducer'
import { tenantSafetySecurityTableDataList } from './home/tenant/pending_verification/safety_security/reducer'
import { tenantHistoryTableData } from 'src/modules/repositories/home/tenant/history/reducer.js'
import { tenantApplicationVerification } from 'src/modules/repositories/home/tenant/application_verification/reducer.js'

import {
  tenantBodyPassDataList,
  bpRejectApplication,
  bpVerifyApplication,
} from './home/tenant/application_verification/body_pass/reducer'
import {
  tenantVehiclePassDataList,
  vpRejectApplication,
  vpVerifyApplication,
} from './home/tenant/application_verification/vehicle_pass/reducer'
import { tenantSafetySecurityPassDataList } from './home/tenant/application_verification/safety_security/reducer'

// AUX POLICE
import { auxBodyPassTableDataList } from './home/aux_police/pending_approval/body_pass/reducer'
import { auxSafetyBriefingTableDataList } from './home/aux_police/pending_approval/safety_security/reducer'
import { auxVehiclePassTableDataList } from './home/aux_police/pending_approval/vehicle_pass/reducer'
import {
  userLostCardProfileData,
  updateLostCardFormData,
  nextAddNewReportData,
  lostCardNewReportFormValidation,
} from 'src/modules/repositories/home/aux_police/lost_card/add_new_report/reducer.js'
import {
  updateLostCardSummaryFormData,
  submitLostCardForm,
} from 'src/modules/repositories/home/aux_police/lost_card/summary/reducer.js'
import { auxHistoryTableDataList } from 'src/modules/repositories/home/aux_police/history/reducer.js'
import {
  auxPaymentHistoryTableDataList,
  auxPaymentHistoryPrintTableDataList,
  auxReceiptData,
} from 'src/modules/repositories/home/aux_police/payment_history/reducer.js'
import { auxPoliceApplicationVerification } from 'src/modules/repositories/home/aux_police/application_verification/reducer.js'
import { auxPoliceLostCardTableData } from 'src/modules/repositories/home/aux_police/lost_card/lost_card/reducer.js'

import {
  auxPoliceBodyPassDataList,
  bpRejectApplicationAP,
  bpVerifyApplicationAP,
} from './home/aux_police/approval_application/body_pass/reducer'
import {
  auxPoliceVehiclePassDataList,
  vpRejectApplicationAP,
  vpVerifyApplicationAP,
} from './home/aux_police/approval_application/vehicle_pass/reducer'
import { auxPoliceSafetySecurityPassDataList } from './home/aux_police/approval_application/safety_security/reducer'

import {
  AuxBodyPassTableByTenant,
  AuxBodyPassPendingTableByTenant,
} from './home/aux_police/pending_verification_by_tenant/body_pass/reducer'
import { AuxVerificationApplicationByTenantBP } from './home/aux_police/verification_application_by_tenant/body_pass/reducer'
import { auxPoliceApplicationApproval } from './home/aux_police/approval_application/reducer'

import {
  AuxVehiclePassTableByTenant,
  AuxVehiclePassPendingTableByTenant,
} from './home/aux_police/pending_verification_by_tenant/vehicle_pass/reducer'
import { AuxVerificationApplicationByTenantVP } from './home/aux_police/verification_application_by_tenant/vehicle_pass/reducer'

import {
  AuxSafetySecurityTableByTenant,
  AuxSafetySecurityPendingTableByTenant,
} from './home/aux_police/pending_verification_by_tenant/safety_security/reducer'
import { AuxVerificationApplicationByTenantSB } from './home/aux_police/verification_application_by_tenant/safety_security/reducer'
import { AuxPaymentCartData } from './home/aux_police/payment_cart/reducer'
import { AuxCashPaymentData } from './home/aux_police/cashPayment/reducer'

export default combineReducers({
  //GENERAL
  layout,
  login,
  tenantDashboardDataList,
  userDashboardDataList,
  statusData,
  auxDashboardDataList,
  apPassInfoData,
  // profileData,
  profilePhoto,
  userProfileData,
  companyProfileData,
  countryDataList,
  userCompany,
  signUp,
  safetyBriefingData,
  submitSafetyBriefingData,
  passInfoData,
  passPrice,
  safetyBriefingFormSubmit,
  safetyBriefingVideoData,
  paymentCartData,
  submitForgotPasswordData,
  submitResetPasswordData,
  adminLogin,

  // CORE
  tenantData,
  tenantDepartmentData,
  workAreaData,
  locationData,
  employerCompanyData,
  phoneCodeData,
  countryData,
  countryStateData,
  licenseClassData,
  vehicleTypeData,
  itemInfoData,

  // USER

  //BODY PASS NEW REGISTRATION
  bodyPassData,
  submitData,
  // workAreaData,
  departmentData,
  // locationData,
  // employerCompanyData,
  formId,
  // phoneCodeData,
  // countryData,
  stateOptionsData,
  officeData,
  compAddressData,
  saveNewRegistrationBodyPassData,
  updateBodyPassFormData,
  uploadICFile,
  uploadICFileBack,
  uploadCidbFile,
  uploadAdditionalDocumentFile,
  uploadProfilePhoto,
  uploadPassportFile,
  uploadWorkPermit,
  uploadOfferLetter,
  uploadReleaseLetter,
  nationalityForeignData,
  bodyPassAppFormValidation,
  bodyPassInfo,

  submitNewBodyPassForm,
  bodyPassSafetyBriefing,
  submitVehicleBodyPassForm,

  //BODY VEHICLE PASS
  saveNewRegistrationBodyVehiclePassData,
  uploadBodyVehicleRoadTaxFile,
  uploadBodyVehicleDrivingLicenseFile,
  uploadBodyVehicleDrivingLicenseBackFile,
  uploadBodyVehicleAdditionalDrivingLicenseFile,
  updateBodyVehicleFormData,
  getBodyVehicleTypeData,
  getBodyLicenseClassData,

  //RENEW BODY PASS
  updateRenewBodyPassFormData,
  renewBodyPassData,
  saveRenewRegistrationBodyPassData,
  departmentDataRenew,
  renewFormValidation,

  renewRegistrationBodyVehiclePassData,
  updateRenewBodyVehicleFormData,

  //VEHICLE PASS NEW REGISTRATION
  saveNewRegistrationVehiclePassData,
  uploadRoadTaxFile,
  uploadDrivingLicenseFile,
  uploadDrivingLicenseBackFile,
  uploadAdditionalDrivingLicenseFile,
  vehiclePassNewRegistrationFormValidation,
  licenseClassList,
  userVehiclePassSummaryData,
  submitVehiclePassForm,
  updateFormData,
  getVehicleTypeData,
  updateRenewFormData,
  nextRenewRegistrationVehiclePassData,
  uploadRenewRoadTaxFile,
  uploadRenewDrivingLicenseFile,
  uploadRenewDrivingLicenseBackFile,
  uploadRenewAdditionalDrivingLicenseFile,
  getVehicle,
  userRenewVehiclePassSummaryData,
  submitRenewVehiclePassForm,

  userHistoryTableData,
  userPaymentHistoryTableDataList,
  userReceiptData,
  applicationPassSummary,
  userApplicationVerification,

  // TENANT
  tenantBodyPassTableDataList,
  tenantVehiclePassTableDataList,
  tenantSafetySecurityTableDataList,
  tenantHistoryTableData,
  tenantApplicationVerification,
  tenantBodyPassDataList,
  tenantVehiclePassDataList,
  tenantSafetySecurityPassDataList,
  tenantPassInfoData,
  // tenantData,
  vpRejectApplication,
  vpVerifyApplication,
  bpRejectApplication,
  bpVerifyApplication,

  // AUX POLICE
  auxBodyPassTableDataList,
  auxSafetyBriefingTableDataList,
  auxVehiclePassTableDataList,
  userLostCardProfileData,
  updateLostCardFormData,
  updateLostCardSummaryFormData,
  nextAddNewReportData,
  submitLostCardForm,
  auxHistoryTableDataList,
  auxPaymentHistoryTableDataList,
  auxPaymentHistoryPrintTableDataList,
  auxReceiptData,
  auxPoliceApplicationVerification,
  auxPoliceBodyPassDataList,
  auxPoliceVehiclePassDataList,
  vpRejectApplicationAP,
  vpVerifyApplicationAP,
  auxPoliceSafetySecurityPassDataList,
  AuxBodyPassTableByTenant,
  AuxVerificationApplicationByTenantBP,
  AuxVehiclePassTableByTenant,
  AuxVerificationApplicationByTenantVP,
  AuxBodyPassPendingTableByTenant,
  AuxVehiclePassPendingTableByTenant,
  AuxSafetySecurityTableByTenant,
  AuxVerificationApplicationByTenantSB,
  AuxSafetySecurityPendingTableByTenant,
  auxPoliceApplicationApproval,
  bpRejectApplicationAP,
  bpVerifyApplicationAP,
  auxPoliceLostCardTableData,
  lostCardNewReportFormValidation,
  AuxPaymentCartData,
  AuxCashPaymentData,
})
