const BODYPASSAP_DATA_ACTIONS = {
  BODYPASSAP_DATA: {
    PENDING: 'BODYPASSAP_DATA_PENDING',
    SUCCESS: 'BODYPASSAP_DATA_SUCCESS',
    FAILURE: 'BODYPASSAP_DATA_FAILURE',
    UPDATE: 'BODYPASSAP_DATA_UPDATE',
    CLEAR: 'BODYPASSAP_DATA_CLEAR',
  },
  VERIFY: {
    PENDING: 'BODYPASSAP_VERIFY_PENDING',
    SUCCESS: 'BODYPASSAP_VERIFY_SUCCESS',
    FAILURE: 'BODYPASSAP_VERIFY_FAILURE',
  },
  REJECT: {
    PENDING: 'BODYPASSAP_REJECT_PENDING',
    SUCCESS: 'BODYPASSAP_REJECT_SUCCESS',
    FAILURE: 'BODYPASSAP_REJECT_FAILURE',
  },
}

export const BODYPASSAP_DATA_STATE = {
  REFRESHED: 'BODYPASSAP_DATA_REFRESHED',
  IN_PROGRESS: 'BODYPASSAP_DATA_IN_PROGRESS',
  SUCCESS: 'BODYPASSAP_DATA_RETRIEVED',
  ERROR: 'BODYPASSAP_DATA_ERROR',
  PENDING: 'BODYPASSAP_DATA_PENDING',
}
export const BODYPASSAP_VERIFY_STATE = {
  REFRESHED: 'BODYPASSAP_VERIFY_REFRESHED',
  IN_PROGRESS: 'BODYPASSAP_VERIFY_IN_PROGRESS',
  SUCCESS: 'BODYPASSAP_VERIFY_RETRIEVED',
  ERROR: 'BODYPASSAP_VERIFY_ERROR',
  PENDING: 'BODYPASSAP_VERIFY_PENDING',
}
export const BODYPASSAP_REJECT_STATE = {
  REFRESHED: 'BODYPASSAP_REJECT_REFRESHED',
  IN_PROGRESS: 'BODYPASSAP_REJECT_IN_PROGRESS',
  SUCCESS: 'BODYPASSAP_REJECT_RETRIEVED',
  ERROR: 'BODYPASSAP_REJECT_ERROR',
  PENDING: 'BODYPASSAP_REJECT_PENDING',
}

export default BODYPASSAP_DATA_ACTIONS
