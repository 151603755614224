const LOSTCARD_ACTIONS = {
  USER_PROFILE: {
    PENDING: 'USER_PROFILE_PENDING',
    SUCCESS: 'USER_PROFILE_SUCCESS',
    FAILURE: 'USER_PROFILE_FAILURE',
    UPDATE: 'USER_PROFILE_UPDATE',
  },
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  FORM_SAVE: {
    PENDING: 'FORM_SAVE_PENDING',
    SUCCESS: 'FORM_SAVE_SUCCESS',
    FAILURE: 'FORM_SAVE_FAILURE',
    UPDATE: 'FORM_SAVE_UPDATE',
  },
  APP_FORM_VALIDATION: {
    PENDING: 'APP_FORM_VALIDATION_PENDING',
    SUCCESS: 'APP_FORM_VALIDATION_SUCCESS',
    FAILURE: 'APP_FORM_VALIDATION_FAILURE',
    UPDATE: 'APP_FORM_VALIDATION_UPDATE',
    CLEAR: 'APP_FORM_VALIDATION_CLEAR',
  },
}

export const USER_PROFILE_STATE = {
  PENDING: 'USER_PROFILE_PENDING',
  REFRESHED: 'USER_PROFILE_REFRESHED',
  IN_PROGRESS: 'USER_PROFILE_PROGRESS',
  SUCCESS: 'USER_PROFILE_RETRIEVED',
  ERROR: 'USER_PROFILE_ERROR',
}

export const FORM_SAVE_STATE = {
  REFRESHED: 'FORM_SAVE_REFRESHED',
  IN_PROGRESS: 'FORM_SAVE_PROGRESS',
  SUCCESS: 'FORM_SAVE_RETRIEVED',
  ERROR: 'FORM_SAVE_ERROR',
  PENDING: 'FORM_SAVE_PENDING',
}
export default LOSTCARD_ACTIONS
