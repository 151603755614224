const RENEW_REGISTRATION_ACTIONS = {
  FORM_NEXT: {
    PENDING: 'FORM_NEXT_PENDING',
    SUCCESS: 'FORM_NEXT_SUCCESS',
    FAILURE: 'FORM_NEXT_FAILURE',
    UPDATE: 'FORM_NEXT_UPDATE',
  },
  FORM_GETID: {
    PENDING: 'FORM_GETID_PENDING',
    SUCCESS: 'FORM_GETID_SUCCESS',
    FAILURE: 'FORM_GETID_FAILURE',
    UPDATE: 'FORM_GETID_UPDATE',
  },
  UPDATE_FORM_DATA: {
    PENDING: 'UPDATE_FORM_DATA_PENDING',
    SUCCESS: 'UPDATE_FORM_DATA_SUCCESS',
    FAILURE: 'UPDATE_FORM_DATA_FAILURE',
    UPDATE: 'UPDATE_FORM_DATA_UPDATE',
    CLEAR: 'UPDATE_FORM_DATA_CLEAR',
  },
  UPLOAD_ROADTAX_FILE: {
    PENDING: 'UPLOAD_ROADTAX_FILE_PENDING',
    SUCCESS: 'UPLOAD_ROADTAX_FILE_SUCCESS',
    FAILURE: 'UPLOAD_ROADTAX_FILE_FAILURE',
    UPDATE: 'UPLOAD_ROADTAX_FILE_UPDATE',
  },
  UPLOAD_DRIVINGLICENSE_FILE: {
    PENDING: 'UPLOAD_DRIVINGLICENSE_FILE_PENDING',
    SUCCESS: 'UPLOAD_DRIVINGLICENSE_FILE_SUCCESS',
    FAILURE: 'UPLOAD_DRIVINGLICENSE_FILE_FAILURE',
    UPDATE: 'UPLOAD_DRIVINGLICENSE_FILE_UPDATE',
  },
  UPLOAD_DRIVINGLICENSEBACK_FILE: {
    PENDING: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PENDING',
    SUCCESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_SUCCESS',
    FAILURE: 'UPLOAD_DRIVINGLICENSEBACK_FILE_FAILURE',
    UPDATE: 'UPLOAD_DRIVINGLICENSEBACK_FILE_UPDATE',
  },
  UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE: {
    PENDING: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PENDING',
    SUCCESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_SUCCESS',
    FAILURE: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_FAILURE',
    UPDATE: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_UPDATE',
  },
  GET_VEHICLE: {
    PENDING: 'GET_VEHICLE_PENDING',
    SUCCESS: 'GET_VEHICLE_SUCCESS',
    FAILURE: 'GET_VEHICLE_FAILURE',
    UPDATE: 'GET_VEHICLE_UPDATE',
  },
  CLEAR_ALL_FORM: 'CLEAR_ALL_FORM',
}

export const FORM_NEXT_STATE = {
  REFRESHED: 'FORM_NEXT_REFRESHED',
  IN_PROGRESS: 'FORM_NEXT_PROGRESS',
  SUCCESS: 'FORM_NEXT_RETRIEVED',
  ERROR: 'FORM_NEXT_ERROR',
  PENDING: 'FORM_NEXT_PENDING',
}

export const FORM_GETID_STATE = {
  REFRESHED: 'FORM_GETID_REFRESHED',
  IN_PROGRESS: 'FORM_GETID_PROGRESS',
  SUCCESS: 'FORM_GETID_RETRIEVED',
  ERROR: 'FORM_GETID_ERROR',
  PENDING: 'FORM_GETID_PENDING',
}
export const UPLOAD_ROADTAX_FILE_STATE = {
  REFRESHED: 'UPLOAD_ROADTAX_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_ROADTAX_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_ROADTAX_FILE_RETRIEVED',
  ERROR: 'UPLOAD_ROADTAX_FILE_ERROR',
  PENDING: 'UPLOAD_ROADTAX_FILE_PENDING',
}

export const UPLOAD_DRIVINGLICENSE_FILE_STATE = {
  REFRESHED: 'UPLOAD_DRIVINGLICENSE_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_DRIVINGLICENSE_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_DRIVINGLICENSE_FILE_RETRIEVED',
  ERROR: 'UPLOAD_DRIVINGLICENSE_FILE_ERROR',
  PENDING: 'UPLOAD_DRIVINGLICENSE_FILE_PENDING',
}

export const UPLOAD_DRIVINGLICENSEBACK_FILE_STATE = {
  REFRESHED: 'UPLOAD_DRIVINGLICENSEBACK_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_DRIVINGLICENSEBACK_FILE_RETRIEVED',
  ERROR: 'UPLOAD_DRIVINGLICENSEBACK_FILE_ERROR',
  PENDING: 'UPLOAD_DRIVINGLICENSEBACK_FILE_PENDING',
}

export const UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_STATE = {
  REFRESHED: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_REFRESHED',
  IN_PROGRESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PROGRESS',
  SUCCESS: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_RETRIEVED',
  ERROR: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_ERROR',
  PENDING: 'UPLOAD_ADDITIONAL_DRIVINGLICENSE_FILE_PENDING',
}

export const GET_VEHICLE_STATE = {
  REFRESHED: 'GET_VEHICLE_REFRESHED',
  IN_PROGRESS: 'GET_VEHICLE_PROGRESS',
  SUCCESS: 'GET_VEHICLE_RETRIEVED',
  ERROR: 'GET_VEHICLE_ERROR',
  PENDING: 'GET_VEHICLE_PENDING',
}

export default RENEW_REGISTRATION_ACTIONS
