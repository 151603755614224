import ACTION, {
  BODYPASSAP_DATA_STATE,
  BODYPASSAP_VERIFY_STATE,
  BODYPASSAP_REJECT_STATE,
} from './creator'

const init = {
  status: BODYPASSAP_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    photoFilename: '',
    fullname: '',
    nationality: '',
    email: '',
    icNumber: '',
    gender: '',
    contactNumber: '',
    residentAddress: '',
    employerCompany: '',
    occupation: '',
    tenant: '',
    companyNo: '',
    address: '',
    location: [],
    workPass: '',
    startDate: '',
    endDate: '',
    platNo: '',
    vehicleType: '',
    roadTaxExpired: '',
    paymentMethod: '',
    newRegBP: '',
    newRegVP: '',
    renewSB: '',
    totalPayment: '',
    oldPassport: '',
    oldPassportExpired: '',
    newPassport: '',
    newPassportExpired: '',
    workPassStartAPConfirm: '',
    statusApproval: 'Approved',
  },
}

const init_verify = {
  status: BODYPASSAP_VERIFY_STATE.PENDING,
  message: '',
}
const init_reject = {
  status: BODYPASSAP_REJECT_STATE.PENDING,
  message: '',
}
export const auxPoliceBodyPassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.BODYPASSAP_DATA.PENDING:
    case ACTION.BODYPASSAP_DATA.SUCCESS:
    case ACTION.BODYPASSAP_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.BODYPASSAP_DATA.UPDATE:
      let _state = { ...state }
      _state.dataForm = { ...state.dataForm, ...payload }
      return _state
    case ACTION.BODYPASSAP_DATA.CLEAR:
      return init
    default:
      return state
  }
}
export const bpVerifyApplicationAP = (state = init_verify, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const bpRejectApplicationAP = (state = init_reject, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
