const PAYMENT_HISTORY_ACTIONS = {
  PAYMENT_HISTORY: {
    PENDING: 'PAYMENT_HISTORY_PENDING',
    SUCCESS: 'PAYMENT_HISTORY_SUCCESS',
    FAILURE: 'PAYMENT_HISTORY_FAILURE',
    UPDATE_LIST: 'PAYMENT_HISTORY_UPDATE_LIST',
  },
  RECEIPT: {
    PENDING: 'RECEIPT_PENDING',
    SUCCESS: 'RECEIPT_SUCCESS',
    FAILURE: 'RECEIPT_FAILURE',
  },
}

export const PAYMENT_HISTORY_STATE = {
  REFRESHED: 'PAYMENT_HISTORY_REFRESHED',
  IN_PROGRESS: 'PAYMENT_HISTORY_IN_PROGRESS',
  SUCCESS: 'PAYMENT_HISTORY_RETRIEVED',
  ERROR: 'PAYMENT_HISTORY_ERROR',
  PENDING: 'PAYMENT_HISTORY_PENDING',
}

export const RECEIPT_STATE = {
  REFRESHED: 'RECEIPT_REFRESHED',
  PENDING: 'RECEIPT_PENDING',
  IN_PROGRESS: 'RECEIPT_IN_PROGRESS',
  SUCCESS: 'RECEIPT_SUCCESS',
  ERROR: 'RECEIPT_ERROR',
}

export default PAYMENT_HISTORY_ACTIONS
