import ACTION, {
  VEHICLEPASSAP_DATA_STATE,
  VEHICLEPASSAP_VERIFY_STATE,
  VEHICLEPASSAP_REJECT_STATE,
} from './creator'

const init = {
  status: VEHICLEPASSAP_DATA_STATE.PENDING,
  message: '',
  dataForm: {
    platNo: '',
    vehicleType: '',
    roadTaxExpired: '',
    drivingLicense: '',
    roadTax: '',
    paymentMethod: '',
    totalPayment: 0,
  },
}

const init_verify = {
  status: VEHICLEPASSAP_VERIFY_STATE.PENDING,
  message: '',
}
const init_reject = {
  status: VEHICLEPASSAP_REJECT_STATE.PENDING,
  message: '',
}

export const auxPoliceVehiclePassDataList = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.VEHICLEPASSAP_DATA.PENDING:
    case ACTION.VEHICLEPASSAP_DATA.SUCCESS:
    case ACTION.VEHICLEPASSAP_DATA.FAILURE:
      return { ...state, ...payload }
    case ACTION.VEHICLEPASSAP_DATA.UPDATE:
      let _state = { ...state }
      _state.dataForm = { ...state.dataForm, ...payload }
      return _state
    default:
      return state
  }
}
export const vpVerifyApplicationAP = (state = init_verify, { type, payload }) => {
  switch (type) {
    case ACTION.VERIFY.PENDING:
    case ACTION.VERIFY.SUCCESS:
    case ACTION.VERIFY.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
export const vpRejectApplicationAP = (state = init_reject, { type, payload }) => {
  switch (type) {
    case ACTION.REJECT.PENDING:
    case ACTION.REJECT.SUCCESS:
    case ACTION.REJECT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
