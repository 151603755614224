import ACTION, { LOSTCARD_STATE } from './creator'
const init = {
  status: LOSTCARD_STATE.PENDING,
  message: '',
  lostCardData: {
    list: [],
    pagination: {
      page: 1,
      itemPerPage: 10,
      count: 0,
      totalCount: 0,
    },
  },
}

export const auxPoliceLostCardTableData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.LOSTCARD.PENDING:
    case ACTION.LOSTCARD.SUCCESS:
      return { ...state, ...payload }
    case ACTION.LOSTCARD.FAILURE:
      return { ...state, ...payload, lostCardData: init.lostCardData }
    case ACTION.LOSTCARD.UPDATE_LIST:
      if (!payload.id) {
        return
      }
      const findIndex = state.lostCardData.list.findIndex((item) => {
        return item.id === payload.id
      })
      if (findIndex !== -1) {
        state.lostCardData.list[findIndex].statusApplication = payload.status
      }

      return { ...state }
    default:
      return state
  }
}
